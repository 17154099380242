.single {
  display: flex;
  width: 100%;
  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 290px;
            height: 310px;
           
            object-fit: cover;
          }

          .details {
            .itemTitle {
              margin-bottom: 15px;
              color: #555;
              background-color: rgb(251, 246, 246);
              border-radius: 4px;
              padding: 2px;
              text-align: left;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;
              border-bottom: 0.5px solid rgb(200, 180, 180);
             
              .strong{
                display:inline-block;
                width:140px;
                text-align:right;
              }
              

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;

              }

              .itemValue {
                font-weight: 800;
               
              }
            }
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}
